<template>
  <v-dialog v-model="open" persistent width="400">
    <v-card>
      <v-card-title>回補到庫存</v-card-title>
      <v-card-text>
        <div class="mb-3">
          <v-text-field
            label="機台商品"
            outlined
            dense
            disabled
            hide-details
            :value="product"
          ></v-text-field>
        </div>
        <div class="mb-3">
          <v-text-field
            label="前次點貨數量"
            outlined
            dense
            disabled
            hide-details
            :value="stall.amount"
          ></v-text-field>
        </div>
        <div class="mb-3">
          <v-text-field
            type="number"
            v-model.number="amount"
            label="回補數量"
            outlined
            dense
            clearable
            hide-details
          ></v-text-field>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn text class="bt-text-large" color="error" @click="open = false">
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text class="bt-text-large" color="primary" @click="confirm">
          確定
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogProductRestock",
  props: ["value", "stall", "product"],
  data: () => ({
    amount: null,
    stock: null,
  }),
  computed: {
    open: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  created() {
    this.getStock();
  },
  methods: {
    async getStock() {
      let { data } = await this.axios.get(`/stock`, {
        params: {
          branch: this.stall.branch,
          productId: this.stall.productId,
        },
      });
      this.stock = data?.[0]?.amount || 0;
    },
    async confirm() {
      try {
        await this.axios.put(
          `/stall/${this.stall.branch}/${this.stall.machine}/destock`,
          { amount: this.amount, productId: this.stall.productId }
        );
        this.$toast.success("回補完成");
        this.$emit("load");
        this.open = false;
      } catch (error) {
        this.$toast.error(error.response?.data?.message || "回補失敗");
      }
    },
  },
};
</script>
