<template>
  <v-dialog v-model="open" persistent width="400">
    <v-card>
      <v-card-title>機台點貨</v-card-title>
      <v-card-text>
        <div class="mb-3">
          <v-text-field
            label="機台商品"
            outlined
            dense
            disabled
            hide-details
            :value="product"
          ></v-text-field>
        </div>
        <div class="mb-3">
          <v-text-field
            label="前次點貨數量"
            outlined
            dense
            disabled
            hide-details
            :value="stall.amount"
          ></v-text-field>
        </div>
        <div class="mb-3">
          <v-text-field
            type="number"
            v-model.number="amount"
            label="點貨數量"
            outlined
            dense
            clearable
            hide-details
          ></v-text-field>
        </div>
        <div class="mb-3 text-right">
          前次點貨時間: {{ stall.lastCheckTime || "-" }}
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn text class="bt-text-large" color="error" @click="open = false">
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text class="bt-text-large" color="primary" @click="confirm">
          確定
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogProductCheck",
  props: ["value", "stall", "product"],
  data: () => ({
    amount: null,
  }),
  computed: {
    open: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  created() {},
  methods: {
    async confirm() {
      try {
        await this.axios.put(
          `/stall/${this.stall.branch}/${this.stall.machine}/check`,
          { amount: this.amount }
        );
        this.$toast.success("點貨完成");
        this.$emit("load");
        this.open = false;
      } catch (error) {
        this.$toast.error(error.response?.data?.message || "點貨失敗");
      }
    },
  },
};
</script>

<style></style>
