<template>
  <v-container fluid>
    <h3 class="page-title">機台管理</h3>
    <v-row>
      <v-col cols="6" sm="3">
        <v-select
          label="店點"
          hide-details
          v-model="branch"
          :items="[
            { text: '請選擇', value: '', disabled: true },
            { text: '文賢店', value: '文賢' },
            { text: '崇善店', value: '崇善' },
            { text: '奇美店', value: '奇美' },
          ]"
          outlined
          dense
        ></v-select>
      </v-col>
      <v-col cols="6" sm="3">
        <v-text-field
          v-model="keyword"
          label="關鍵字"
          outlined
          dense
          clearable
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="filterData"
          :mobile-breakpoint="0"
          item-key="index"
          hide-default-footer
          :footer-props="{
            itemsPerPageText: '每頁幾筆',
            itemsPerPageOptions: [-1],
          }"
          :height="'80vh'"
          fixed-header
        >
          <template v-slot:item.action="{ item }">
            <v-menu v-if="isMobile">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark v-bind="attrs" v-on="on">
                  操作
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-list-item-title
                    class="success--text font-weight-bold"
                    @click="openDialog(item, 'productRestock')"
                  >
                    補貨
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title
                    class="warning--text font-weight-bold"
                    @click="openDialog(item, 'productCheck')"
                  >
                    點貨
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title
                    class="error--text font-weight-bold"
                    @click="openDialog(item, 'productChange')"
                  >
                    換貨</v-list-item-title
                  >
                </v-list-item>
                <v-list-item>
                  <v-list-item-title
                    class="secondary--text font-weight-bold"
                    @click="openDialog(item, 'productDestock')"
                  >
                    回補
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <div class="my-1" v-else>
              <v-btn
                class="my-1 mx-2"
                color="success"
                @click="openDialog(item, 'productRestock')"
              >
                補貨
              </v-btn>
              <v-btn
                class="my-1 mx-2"
                color="warning"
                @click="openDialog(item, 'productCheck')"
              >
                點貨
              </v-btn>
              <v-btn
                class="my-1 mx-2"
                color="error"
                @click="openDialog(item, 'productChange')"
              >
                換貨
              </v-btn>
              <v-btn
                class="my-1 mx-2"
                color="secondary"
                @click="openDialog(item, 'productDestock')"
              >
                回補
              </v-btn>
            </div>
          </template>
          <template v-slot:item.product="{ item }">
            <div class="my-1">
              {{ mapProducts[item.productId] }}
              {{ mapProductPoints[item.productId] }}
              <div
                class="font-small"
                :class="{
                  'warning--text': isLessThanDay(item.lastRestockTime, 1),
                  'font-weight-bold': isLessThanDay(item.lastRestockTime, 1),
                }"
              >
                補貨: {{ item.lastRestockTime }}
              </div>
              <div
                class="font-small"
                :class="{
                  'error--text': isLessThanDay(item.lastCheckTime, 1),
                  'font-weight-bold': isLessThanDay(item.lastCheckTime, 1),
                }"
              >
                點貨: {{ item.lastCheckTime }}
              </div>
              <div class="font-small">
                換貨: {{ item.lastResetTime ?? item.lastDestockTime }}
              </div>
            </div>
          </template>
          <template v-slot:item.expired="{ item }">
            <span
              :class="{
                'grey--text': !stockMap[item.productId]?.expired,
                'font-weight-medium': !!stockMap[item.productId]?.expired,
                'red--text': isNearExpired(stockMap[item.productId]?.expired),
              }"
            >
              {{ stockMap[item.productId]?.expired }}
            </span>
          </template>
          <!-- <template v-slot:item.lottery="{ item }">
            <div>{{ item.remainDraws }} / {{ item.totalDraws }}</div>
            <v-btn @click="openDialog(item, 'lotterySetting')" color="primary">
              <v-icon small>mdi-cog</v-icon>
              換抽
            </v-btn>
          </template> -->
        </v-data-table>
      </v-col>
    </v-row>
    <DialogProductChange
      v-if="dialog.productChange"
      v-model="dialog.productChange"
      :stall="selectedStall"
      :products="products"
      :mapProducts="mapProducts"
      @load="load"
      :rawProducts="rawProducts"
    />
    <DialogProductCheck
      v-if="dialog.productCheck"
      v-model="dialog.productCheck"
      :stall="selectedStall"
      :product="mapProducts[selectedStall.productId]"
      @load="load"
    />
    <DialogProductRestock
      v-if="dialog.productRestock"
      v-model="dialog.productRestock"
      :stall="selectedStall"
      :product="mapProducts[selectedStall.productId]"
      @load="load"
    />
    <DialogProductDestock
      v-if="dialog.productDestock"
      v-model="dialog.productDestock"
      :stall="selectedStall"
      :product="mapProducts[selectedStall.productId]"
      @load="load"
    />
    <!-- <DialogLotterySetting
      v-if="dialog.lotterySetting"
      v-model="dialog.lotterySetting"
      :stall="selectedStall"
      @load="load"
    /> -->
  </v-container>
</template>

<script>
import DialogProductChange from "@/components/dashboard/product/DialogProductChange.vue";
import DialogProductCheck from "@/components/dashboard/product/DialogProductCheck.vue";
import DialogProductRestock from "@/components/dashboard/product/DialogProductRestock.vue";
import DialogProductDestock from "@/components/dashboard/product/DialogProductDestock.vue";
// import DialogLotterySetting from "@/components/dashboard/lottery/DialogLotterySetting.vue";

import machineNum from "@/config/machineNum.json";
import util from "@/mixins/util";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import _ from "lodash";
dayjs.extend(isBetween);

export default {
  name: "Stall",
  mixins: [util],
  components: {
    DialogProductChange,
    DialogProductCheck,
    DialogProductRestock,
    DialogProductDestock,
    // DialogLotterySetting,
  },
  data: () => ({
    dialog: {
      productChange: false,
      productCheck: false,
      productRestock: false,
      productDestock: false,
      // lotterySetting: false,
    },
    data: [],
    stockMap: {},
    products: [],
    rawProducts: [],
    selectedStall: null,
    branch: null,
    keyword: "",
    headers: [
      {
        text: "機台位",
        value: "machine",
        align: "center",
        sortable: false,
        cellClass: "font-weight-regular",
      },
      {
        text: "機台商品",
        value: "product",
        align: "left",
        sortable: false,
      },
      {
        text: "上次點貨",
        value: "amount",
        align: "right",
        sortable: false,
      },
      {
        text: "庫存效期",
        value: "expired",
        align: "center",
        sortable: false,
      },
      {
        text: "操作",
        value: "action",
        align: "center",
        sortable: false,
        width: util.computed.isMobile() ? 100 : "auto",
      },
      // {
      //   text: "換抽",
      //   value: "lottery",
      //   align: "center",
      //   sortable: false,
      // },
    ],
  }),
  computed: {
    mapProductPoints() {
      let products = {};
      this.products.forEach((item) => {
        products[item.value] = item.points ? ` [${item.points}]` : "";
      });
      return products;
    },
    mapProducts() {
      let products = {};
      this.products.forEach((item) => {
        products[item.value] = item.text;
      });
      return products;
    },
    filterData() {
      let data = this.data;

      if (this.keyword) {
        let mapProducts = this.products
          .filter((o) => o.text?.includes(this.keyword) || false)
          .map((o) => o.value);
        data = data.filter(
          (item) =>
            item.machine == this.keyword || mapProducts?.includes(item.product)
        );
      }
      return data;
    },
  },
  async created() {
    await this.getProducts();
    this.branch = this.$store.state.branch;
  },
  methods: {
    isLessThanDay(targetDate, dayNum) {
      return dayjs().diff(targetDate, "day") > 1;
    },
    openDialog(stall, type) {
      this.dialog[type] = true;
      this.selectedStall = stall;
    },
    async getProducts() {
      const res = await this.axios.get(`/product?type=機台商品`);
      this.rawProducts = res.data;
      let product = res.data.map((item) => ({
        text: item.name,
        value: item._id,
        points: item.points,
      }));
      product = _.uniqBy(product, "text");
      product.unshift({ text: "-", value: "" });
      this.products = product;
    },
    async getStock() {
      const { data } = await this.axios.get(`/stock`, {
        params: {
          branch: this.branch,
        },
      });
      this.stockMap = _.keyBy(data, "productId");
    },
    async load() {
      if (!this.ready && !this.branch) return;
      const { data } = await this.axios.get(`/stall`);
      await this.getStock();
      await this.createStall(data);

      // get array from 1 to 67
      let stall = machineNum[this.branch] ?? [];
      stall = stall.map((item) => ({
        branch: this.branch,
        machine: item.toString(),
        productId: "",
        ..._.find(data, { branch: this.branch, machine: item.toString() }),
      }));

      this.data = stall;
    },
    async createStall(data) {
      const stall = machineNum[this.branch] ?? [];
      const lackStall = stall.filter((machine) => {
        const stall = _.find(data, {
          branch: this.branch,
          machine: machine.toString(),
        });
        return !stall;
      });
      const process = lackStall.map((machine) => {
        this.axios.post(`/stall`, {
          branch: this.branch,
          machine: machine.toString(),
          amount: 0,
        });
      });
      await Promise.all(process);
    },
    async update(stall, key, e) {
      await this.axios.put(`/stall/${stall.branch}/${stall.machine}`, {
        [key]: e.value,
      });
    },
    isNearExpired(date) {
      if (!date) return false;
      const today = dayjs();
      const twoMonthsLater = today.add(2, "month");
      return dayjs(date).isBetween(today, twoMonthsLater);
    },
  },
  watch: {
    branch(val) {
      this.$store.commit("setState", { branch: val });
      this.load();
    },
  },
};
</script>

<style scoped lang="scss">
.font-small {
  font-size: 11px;
}

// .page-container {
//   display: flex;
//   flex-direction: column;
//   height: calc(100vh - 36px);

//   &__header {
//     flex: 0 0 auto;
//   }

//   &__table {
//     flex: 1 1 auto;
//     overflow: auto;
//   }
// }
</style>
