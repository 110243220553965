<template>
  <v-dialog v-model="open" persistent width="400">
    <v-card>
      <v-card-title>機台換貨</v-card-title>
      <v-card-text>
        <div class="mb-2">
          <v-text-field
            label="回收商品"
            outlined
            dense
            disabled
            hide-details
            :value="mapProducts[stall.productId]"
          ></v-text-field>
        </div>
        <div>
          <v-text-field
            type="number"
            v-model.number="destockAmount"
            label="回收數量"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </div>
        <div class="text-center my-3">- 換 -</div>
        <div class="mb-2">
          <v-combobox
            :items="products"
            :value="mapProducts[restockProductId]"
            label="換貨商品"
            outlined
            dense
            hide-details
            @change="restockProductId = $event.value"
          ></v-combobox>
        </div>
        <div class="mb-2">
          <v-text-field
            v-model.number="restockStock"
            label="商品庫存"
            outlined
            dense
            hide-details
            clearable
            disabled
          ></v-text-field>
        </div>
        <div class="mb-2">
          <v-text-field
            type="number"
            v-model.number="restockAmount"
            label="換貨數量"
            outlined
            dense
            hide-details
            clearable
          ></v-text-field>
        </div>
        <div class="mb-3 text-right">
          前次換貨時間:
          {{ stall.lastRestockTime || stall.lastDestockTime || "-" }}
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn class="bt-text-large" text color="error" @click="open = false">
          取消
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text class="bt-text-large" color="primary" @click="confirm">
          確定
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "DialogProductChange",
  props: ["value", "stall", "products", "mapProducts", "rawProducts"],
  data: () => ({
    destockProductId: null,
    destockAmount: null,
    restockProductId: null,
    restockAmount: null,
    restockStock: null,
    periods: [],
    period: {
      branch: null,
      machine: null,
      product: null,
      start: { coin: 0, eye: 0, date: "" },
      end: { coin: 0, eye: 0, date: "" },
    },
  }),
  computed: {
    open: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    correspondPeriod() {
      return this.periods.find(
        (period) =>
          period.product._id === this.stall.productId &&
          period.branch === this.stall.branch &&
          period.machine === this.stall.machine &&
          !period.end.date &&
          dayjs().isAfter(period.start.date)
      );
    },
  },
  async created() {
    this.destockProductId = this.stall.productId;
    await this.getPeriods();
  },
  methods: {
    async getStock() {
      let { data } = await this.axios.get(`/stock`, {
        params: {
          branch: this.stall.branch,
          productId: this.restockProductId,
        },
      });
      this.restockStock = data?.[0]?.amount || 0;
    },
    async confirm() {
      try {
        this.$vloading.show();

        // 驗證
        if (this.destockProductId) {
          if (this.destockAmount === null || this.destockAmount < 0) {
            throw "請填寫回收數量";
            return;
          }
        }
        if (this.restockProductId) {
          if (this.restockAmount === null || this.restockAmount < 0) {
            throw "請填換貨數量";
            return;
          }
          if (this.restockProductId == this.destockProductId) {
            throw "回收商品不可與換貨商品相同";
            return;
          }
        }

        // 補回 stock 數量
        if (this.destockProductId) {
          //如為換貨，則協助點貨（因“機台內的量全數補回”，通常會大於“機台庫存”）
          if (this.restockProductId) {
            await this.axios.put(
              `/stall/${this.stall.branch}/${this.stall.machine}/check`,
              { amount: this.destockAmount }
            );
          }

          await this.axios.put(
            `/stall/${this.stall.branch}/${this.stall.machine}/destock`,
            {
              productId: this.destockProductId,
              amount: this.destockAmount,
            }
          );
        }

        if (this.restockProductId) {
          //換貨的話歸 0 機台、更新檔期
          await this.axios.put(`/stall/${this.stall._id}/reset`);
          await this.updatePeriod(this.correspondPeriod);

          // todo: 更換商品 ID、建立檔期
          await this.axios.put(
            `/stall/${this.stall.branch}/${this.stall.machine}`,
            {
              productId: this.restockProductId,
            }
          );
          await this.createPeriod();

          // todo: 補貨 stock 數量
          if (this.restockAmount) {
            try {
              await this.axios.put(
                `/stall/${this.stall.branch}/${this.stall.machine}/restock`,
                {
                  productId: this.restockProductId,
                  amount: this.restockAmount,
                }
              );
            } catch (error) {
              console.log(error);
              this.$toast.error("補貨失敗");
            }
          }
        }

        this.$toast.success("換貨完成");
        this.$emit("load");
        this.open = false;
        this.$vloading.hide();
      } catch (error) {
        this.$toast.error(error.response?.data?.message || error || "換貨失敗");
        this.$vloading.hide();
      }
    },
    async createPeriod() {
      const product = this.rawProducts.find(
        (product) => product._id === this.restockProductId
      );
      if (!product) return;

      try {
        await this.axios.post(`/period`, {
          branch: this.stall.branch,
          machine: this.stall.machine,
          product,
          start: {
            coin: 0,
            eye: 0,
            date: dayjs().format("YYYY/MM/DD HH:mm"),
          },
          end: {
            coin: 0,
            eye: 0,
            date: "",
          },
        });
      } catch (error) {}
    },
    async updatePeriod(period) {
      console.log(period);
      if (!period) return;

      try {
        const { _id, ...payload } = period;
        await this.axios.put(`/period/${_id}`, {
          ...period,
          end: {
            ...period.end,
            date: dayjs().format("YYYY/MM/DD HH:mm"),
          },
        });
      } catch (error) {}
    },
    async getPeriods() {
      const { data } = await this.axios.get(`/period`);
      this.periods = data;
    },
  },
  watch: {
    restockProductId() {
      this.getStock();
    },
  },
};
</script>
